import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Spring Médicare</title>
        <meta name="description" content="Spring Médicare simplifie votre vie au quotidien." />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        

        <div className="bg-white">
            <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">Merci pour votre demande !</span>
                <span className="block">Notre praticien va revenir vers vous rapidement.</span>
                </h2>
                <div className="mt-8 flex justify-center">
                    <p>
                        <a className="text-blue-500" href="/">Revenir à l'accueil</a>
                    </p>
                </div>
            </div>
        </div>
        

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}